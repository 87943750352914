<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import draggable from 'vuedraggable'

	export default {
		name: "BoPackage",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "BoPackage",
				row1: {},
				font_style: {},
				font_size: {},
				sub_font_size: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
		},
		methods: {
			submitHero() {
				BOGen.apirest('/' + this.Name, {
					data: this.row1,
					type: 'updateHero'
				}, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 2000, '.hero-info')
						this.refreshData()
						setTimeout(() => {
							$('[href="#collapseExample"]').click()
						}, 2100)
					}
				}, "POST");
			},
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<VForm @resp="submitHero" method="post">
						<div class="card-body">
							<h5 class="card-title">Hero Image</h5>
							<div class="row">
								<div class="col-md-6">
									<div class="wrap_slider_img">
										<img :src="uploader(row1.ash_image_desktop)" class="img-responsive" />
										<div class="slider_name">
											<p>Hero Image</p>
										</div>
										<a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
											aria-controls="collapseExample" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
									</div>
								</div>
							</div>
							<div class="row collapse mt-4" id="collapseExample">
								<div class="hero-info col-12"></div>
								<div class="col-md-4 mb-3">
									<BoField name="ash_image_desktop" mandatory>
										<Uploader :param="{thumbnail:true}" name="ash_image_desktop" v-model="row1.ash_image_desktop"
											type="hero_descktop" uploadType="cropping"></Uploader>
									</BoField>
									<BoField name="ash_image_mobile" mandatory>
										<Uploader :param="{thumbnail:true}" name="ash_image_mobile" v-model="row1.ash_image_mobile"
											type="hero_mobile" uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Heading</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="ash_title_en" v-model="row1.ash_title_en"></BoField>
														</div>
														<div class="col-md-9">
															<BoField name="ash_title_id" v-model="row1.ash_title_id"></BoField>
														</div>
														<div class="col-md-4">
															<div class="form-group">
																<label class="control-label">Font Style</label>
																<select class="form-control" v-model="row1.ash_title_font_type">
																	<option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
																</select>
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<label class="control-label">Font Size </label>
																<select class="form-control" v-model="row1.ash_title_size">
																	<option v-for="(v,k) in font_size" :key="k" :value="v">{{v}}</option>
																</select>
															</div>
														</div>
														<div class="col-md-2">
															<BoField :attr="{type:'color'}" name="ash_title_color" v-model="row1.ash_title_color">
															</BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-12">
											<div class="row">
												<div class="col-md-12">
													<div class="panel_group">
														<div class="panel_heading">Sub-Heading</div>
														<div class="panel_body">
															<div class="row">
																<div class="col-md-9">
																	<div class="form-group">
																		<BoField name="ash_sub_title_en" v-model="row1.ash_sub_title_en"></BoField>
																	</div>
																</div>
																<div class="col-md-9">
																	<BoField name="ash_sub_title_id" v-model="row1.ash_sub_title_id"></BoField>
																</div>
																<div class="col-md-4">
																	<div class="form-group">
																		<label class="control-label">Font Style</label>
																		<select class="form-control" v-model="row1.ash_sub_title_font_type">
																			<option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
																		</select>
																	</div>
																</div>
																<div class="col-md-2">
																	<div class="form-group">
																		<label class="control-label">Font Size </label>
																		<select class="form-control" v-model="row1.ash_sub_title_size">
																			<option v-for="(v,k) in sub_font_size" :key="k" :value="v">{{v}}</option>
																		</select>
																	</div>
																</div>
																<div class="col-md-2">
																	<div class="form-group">
																		<label class="control-label">Color </label>
																		<input class="form-control" type="color" id="favcolor" name="favcolor"
																			v-model="row1.ash_sub_title_color">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-4 mt-3">
											<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</VForm>
				</div>
			</div>


			<div class="col-lg-12">
				<div class="card">
					<!-- <div class="card"> -->
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">Package List</h5>
								</div>
								<div class="col-sm-3">
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
										<input type="text" v-model="filter.search" v-on:keyup.enter="search"  class="form-control" placeholder="Search...">
										<div class="input-group-append">
											<button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
										</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="card-body">
						<form action="#">
							<div class="form-body">
								<!-- <div class="row">
									<div class="col-md-10">
										<h5 class="card-title">Package List</h5>
									</div>

								</div> -->

								<draggable class="row" v-model="data.data" tag="tbody" @end="endDrag">
									<div class="col-md-6" v-for="(v,k) in data.data" :key="k" style="cursor: move;">
										<div class="pacakge_wrap">
											<div class="img_wine">
												<img :src="uploader(v.ap_image)" alt="prod">
											</div>
											<h3>{{v.ap_package_name_en}}</h3>
											<p>{{v.price}}
											</p>
											<div v-html="v.ap_package_desc_en"></div>
											<span class="label label-success" v-if="v.ap_is_active == 'Y'">Active</span>
											<span class="label label-danger" v-else>Inactive</span>
										</div>
										<router-link role="button" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"
											class="bullet_edit  mr-1"><i class="fas fa-pencil-alt"></i></router-link>
										<a href="javascript:;" class="bullet_delete mr-2" @click="deleteItem($event,k)"
											v-tooltip="'Remove'"><i class="far fa-trash-alt"></i></a>
									</div>
								</draggable>
								<div class="row" v-if="NotFound">
									<div class="col-12 text-center">
										<h3 class="tc">{{NotFound}}</h3>
									</div>
								</div>
								<div class="row" v-if="data.data===false">
									<div class="col-12 text-center">
										<LoadingSpinner light></LoadingSpinner>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<Pagination class="float-right" :data="data" :limit="3" @pagination-change-page="onPaging">
										</Pagination>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="col-lg-12">
					<div class="card">
						<div class="card-body">
							<form action="#">
								<div class="form-body">
									<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
									<hr>
									<div class="row">
										<div class="col-12 info"></div>
										<div class="col-lg-8 col-md-7">
											<div class="row">
												<div class="col-md-12">
													<BoField :attr="{placeholder:'e.g Sip Sip Horay'}" name="ap_package_name_en"
														v-model="row.ap_package_name_en"></BoField>
													<BoField name="ap_package_desc_en">
														<CKEditor name="ap_package_desc_en" v-model="row.ap_package_desc_en"
															v-bind="validation('ap_package_desc_en')">
														</CKEditor>
													</BoField>
												</div>
												<div class="col-md-12">
													<BoField name="ap_package_name_id" :attr="{placeholder:'e.g Sip Sip Horay'}"
														v-model="row.ap_package_name_id"></BoField>
													<BoField name="ap_package_desc_id">
														<CKEditor name="ap_package_desc_id" v-model="row.ap_package_desc_id"
															v-bind="validation('ap_package_desc_id')">
														</CKEditor>
													</BoField>
												</div>

												<div class="col-md-4">
													<div class="form-group">
														<label class="control-label">Price <span class="text-danger mr5">*</span> </label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1">IDR</span>
															</div>
															<!-- <input type="number" maxlength="12" minlength="3" v-model="row.ap_price"
																class="form-control" placeholder="e.g. 9.000.000" aria-label="price"
																aria-describedby="basic-addon1"> -->
																<InputCurrency class="form-control" v-model="row.ap_price" placeholder="e.g. 9.000.000"></InputCurrency>
														</div>
													</div>
													<div class="form-group">
														<label class="control-label">PAX <span class="text-danger mr5">*</span> </label>
														<div class="input-group mb-3">
															<input type="number" maxlength="12" minlength="3" v-model="row.ap_pax"
																class="form-control" placeholder="e.g. 9.000.000" aria-label="price"
																aria-describedby="basic-addon1">
																<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1">PAX</span>
															</div>
														</div>
													</div>
													<!-- <BoField name="ap_pax" v-model="row.ap_pax"></BoField> -->
												</div>
											</div>
										</div>
										<div class="col-lg-4 col-md-5 mt-3">
											<h5 class="card-title"></h5>
											<div class="row">
												<div class="col-md-12">
													<BoField name="ap_image" mandatory>
														<Uploader :param="{thumbnail:true}" name="ap_image" v-model="row.ap_image" type="package"
															uploadType="cropping"></Uploader>
													</BoField>
												</div>
											</div>
										</div>
										<div class="col-lg-4 col-md-8 mt-3">
											<BoField name="ap_is_active">
												<div class="row">
													<radio name="ap_is_active" v-model="row.ap_is_active" option="Y"
														:attr="validation('ap_is_active')">Active</radio>
													<radio name="ap_is_active" v-model="row.ap_is_active" option="N">Inactive</radio>
												</div>
											</BoField>
										</div>
									</div>
								</div>
							</form>
							<div class="text-right">
								<button type="submit" class="btn btn-info btn-loading btn-rounded">Save Package</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>